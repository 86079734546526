var YouLi = YouLi ? YouLi : {};
YouLi.InternationalPhoneNumber = YouLi.InternationalPhoneNumber ? YouLi.InternationalPhoneNumber : {};

(function (YouLi) {
    (function (InternationalPhoneNumber) {
        YouLi.InternationalPhoneNumber.CountryCodeList = "";

        YouLi.InternationalPhoneNumber.SetInternationalPhoneNumber = function (uniquePrefixId, htmlFieldPrefix) {
            var phoneNumberId = $(YouLi.InternationalPhoneNumber.GetUniqueId(htmlFieldPrefix));
            var phoneNumber = $(phoneNumberId).val();

            if (phoneNumber != "") {
                var countryCodesId = "#CountryCodes-" + uniquePrefixId;
                var internationalPhoneNumberId = "#InternationalPhoneNumber-" + uniquePrefixId;

                // we first use this more precise regex to try extract the country code and phone number
                var regex1 = new RegExp(`^\\s*\\+(${YouLi.InternationalPhoneNumber.CountryCodeList})([\\d\\s\\.\\-\\(\\)\\/]+)$`, 'g');

                var regex1Matches = phoneNumber.matchAll(regex1);
                var matchedArray1 = [];
                for (var match of regex1Matches) {
                    matchedArray1 = match;
                }
                if (matchedArray1.length == 3) {
                    if ($(countryCodesId + " option[value='+" + matchedArray1[1].trim() + "']").length > 0) {
                        $(countryCodesId).val("+" + matchedArray1[1].trim());
                        $(internationalPhoneNumberId).val(matchedArray1[2].trim());

                        $(phoneNumberId).val($(countryCodesId).val() + " " + $(internationalPhoneNumberId).val());
                    } else {
                        $(internationalPhoneNumberId).val(phoneNumber.trim());
                    }

                } else {
                    // if the above regex doesnt match we try match and extract the country code using this regex
                    var regex2 = /^\s*\(?\s*\+{1}\s*(\d{1,3})\s*\)?[\s\-\.]*([\d\s\.\-\(\)\/]+)$/g;
                    var regex2Matches = phoneNumber.matchAll(regex2);
                    var matchedArray2 = [];
                    for (var match of regex2Matches) {
                        matchedArray2 = match;
                    }
                    if (matchedArray2.length == 3) {
                        if ($(countryCodesId + " option[value='+" + matchedArray2[1].trim() + "']").length > 0) {
                            $(countryCodesId).val("+" + matchedArray2[1].trim());
                            $(internationalPhoneNumberId).val(matchedArray2[2].trim());

                            $(phoneNumberId).val($(countryCodesId).val() + " " + $(internationalPhoneNumberId).val());
                        } else {
                            $(internationalPhoneNumberId).val(phoneNumber.trim());
                        }
                    } else {
                        //if no regex matches we just set the phone number without a country code
                        $(internationalPhoneNumberId).val(phoneNumber.trim());
                    }
                }         
            }
        };

        YouLi.InternationalPhoneNumber.GetUniqueId = function (htmlFieldPrefix) {
            return "#" + htmlFieldPrefix.replaceAll("[", "_").replaceAll("].", "__");
        }

}(YouLi.AddEditTask ? YouLi.AddEditTask : {}));
}(YouLi ? YouLi : {}));