var YouLi = YouLi ? YouLi : {};
YouLi.TaskResponseField = YouLi.TaskResponseField ? YouLi.TaskResponseField : {};
YouLi.TaskResponseField.Checkbox = YouLi.TaskResponseField.Checkbox ? YouLi.TaskResponseField.Checkbox : {};

(function (YouLi) {
    (function (TaskResponseField) {
        (function (Checkbox) {
            $(function () {
                $(document)
                    .off('change', '.task-response-field-checkbox input[type="checkbox"]')
                    .on('change', '.task-response-field-checkbox input[type="checkbox"]', function () {
                        const isChecked = $(this).val();
                        if (isChecked === 'false') {
                            $(this).val('true');
                        } else {
                            $(this).val('false');
                        }
                    })
            });
        }(YouLi.TaskResponseField.Checkbox ? YouLi.TaskResponseField.Checkbox : {}));
    }(YouLi.TaskResponseField ? YouLi.TaskResponseField : {}));
}(YouLi ? YouLi : {}));