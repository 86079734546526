var YouLi = YouLi ? YouLi : {};
YouLi.DateDropdowns = YouLi.DateDropdowns ? YouLi.DateDropdowns : {};

(function (YouLi) {
    (function (DateDropdowns) {

        YouLi.DateDropdowns.InitializeDate = function (dateSelector, daySelector, monthSelector, yearSelector) {
            var date = $(dateSelector);
            if (date.val() != "") {
                var dateString = moment(date.val(), "DD/MM/YYYY HH:mm:ss AM/PM").format("YYYY-MM-DD")
                var year = dateString.split("-")[0];
                var month = dateString.split("-")[1];
                var day = dateString.split("-")[2];
                YouLi.DateDropdowns.SetDayDropdownValues(daySelector, year, month);
                $(daySelector).val(day);
                $(monthSelector).val(month);
                $(yearSelector).val(year);
                YouLi.DateDropdowns.SetDate(dateSelector, daySelector, monthSelector, yearSelector)
            }
        };

        YouLi.DateDropdowns.SetDayDropdownValues = function (daySelector, year, month) {
            if (month != "") {
                if (month == 01 || month == 03 || month == 05 || month == 07 || month == 08 || month == 10 || month == 12) {
                    if ($(daySelector + ' option[value="' + "29" + '"]').length == 0) {
                        $(daySelector).append($('<option></option>').val("29").html("29"));
                    }
                    if ($(daySelector + ' option[value="' + "30" + '"]').length == 0) {
                        $(daySelector).append($('<option></option>').val("30").html("30"));
                    }
                    if ($(daySelector + ' option[value="' + "31" + '"]').length == 0) {
                        $(daySelector).append($('<option></option>').val("31").html("31"));
                    }
                }
                if (month == 04 || month == 06 || month == 09 || month == 11) {
                    if ($(daySelector + ' option[value="' + "29" + '"]').length == 0) {
                        $(daySelector).append($('<option></option>').val("29").html("29"));
                    }
                    if ($(daySelector + ' option[value="' + "30" + '"]').length == 0) {
                        $(daySelector).append($('<option></option>').val("30").html("30"));
                    }
                    if ($(daySelector + ' option[value="' + "31" + '"]').length == 1) {
                        $(daySelector + " option[value='31']").remove();
                    }
                }
                if (month == 02) {
                    if ($(daySelector + ' option[value="' + "31" + '"]').length == 1) {
                        $(daySelector + " option[value='31']").remove();
                    }
                    if ($(daySelector + ' option[value="' + "30" + '"]').length == 1) {
                        $(daySelector + " option[value='30']").remove();
                    }
                    if (leapYear(year)) {
                        if ($(daySelector + ' option[value="' + "29" + '"]').length == 0) {
                            $(daySelector).append($('<option></option>').val("29").html("29"));
                        }
                    } else {
                        if ($(daySelector + ' option[value="' + "29" + '"]').length == 1) {
                            $(daySelector + " option[value='29']").remove();
                        }
                    }
                }
            }
        }

        YouLi.DateDropdowns.SetDate = function (dateSelector, daySelector, monthSelector, yearSelector) {
            if ($(yearSelector).val() == "" || $(monthSelector).val() == "" || $(daySelector).val() == "") {
                $(dateSelector).val("");
            } else {
                var dateString = $(yearSelector).val() + "-" + $(monthSelector).val() + "-" + $(daySelector).val();
                $(dateSelector).val(moment(dateString).format("DD MMM YYYY"));
            }
        };

        function leapYear(year) {
            if (year != "") {
                return ((year % 4 == 0) && (year % 100 != 0)) || (year % 400 == 0);
            }
            return false;
        }

    }(YouLi.DateDropdowns ? YouLi.DateDropdowns : {}));
}(YouLi ? YouLi : {}));